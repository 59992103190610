<template>
    <div class="loan">
       <div class="navTitle"><img src="../assets/img/arrl.png" alt="" @click="goBack">我要贷款</div>
       <div class="loan-card">
           <img src="../assets/img/loan1.png" alt="">
           <div class="loan-title">选择金融超市产品</div>
           <router-link to="loanProduct" class="loan-btn">查看现有产品</router-link>
       </div>
       <div class="loan-card">
           <img src="../assets/img/loan2.png" alt="">
           <div class="loan-title">指定银行申请贷款</div>
           <div class="loan-btn" @click="loanApply()">定向需求</div>
       </div>
       <div class="loan-card">
           <img src="../assets/img/loan3.png" alt="">
           <div class="loan-title">非指定银行申请贷款</div>
           <div class="loan-btn" @click="loanApply2()">不定向需求</div>
       </div>
    </div>
</template>
<script>
import { Toast,Dialog } from 'vant';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
    setup() {
        let router = useRouter();
        let store = useStore();
        let token = store.state.token;  
        store.dispatch('setRequireInfo',{});
        store.dispatch('setRequestData',{});
        // 指定银行申请贷款
      const loanApply = () =>{            
        if(token) {
          router.push({
            path: '/apply2',
            query: {
              reqFlag: '0'
            }
          });
        }else {
          Dialog.confirm({
            title: '提示！',
            confirmButtonText: '去登录',
            message:
              '您还没有登录！点击下方按钮去登录。',
            })
            .then(() => {
              jumpLogin();
            })
            .catch(() => {
              console.log('取消登录!');
          });
        }
        
      };
       // 非指定银行申请贷款
      const loanApply2 = () =>{            
        if(token) {
            if(store.state.loginInfo.isOrgAuth === '0') {
                Dialog.alert({
                  message: '未认证企业不能进行【非指定银行贷款】，请先【选择银行信贷产品】申请贷款或者【指定银行贷款】，待银行认证后即可进行【非指定银行贷款】',
                  theme: 'round-button',
                }).then(() => {
                  // on close
                  return;
                });
                
            }else {
                router.push({
                    path: '/apply2',
                    query: {
                        reqFlag: '1'
                    }
                });
            }          
        }else {
          Dialog.confirm({
            title: '提示！',
            confirmButtonText: '去登录',
            message:
              '您还没有登录！点击下方按钮去登录。',
            })
            .then(() => {
              jumpLogin();
            })
            .catch(() => {
              console.log('取消登录!');
          });
        }
        
      };
      //跳转登录页
      const jumpLogin = () => {
        if(store.state.isiOS) {
          try {
            window.webkit.messageHandlers.ocLogin.postMessage(null);
          } catch (e) {
            alert('ios跳转登录调用失败');
          }
        }else {
          router.push({path: '/login'});
        }
      }
      return {
          loanApply,
          loanApply2
      }
    },
    
    methods:{
        goBack(){
            this.$router.go(-1);
        },
    },
}
</script>
<style lang="less" scoped>
.loan {
    padding-top: 12.5vw;
    .loan-card {
        margin: 4vw;
        width: 92vw;
        height: 26vw;
        border-radius: 1.5vw;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
        }
        .loan-title {
            font-size: 5vw;
            font-weight: 600;
            color: #fff;
            margin-left: 40vw;
            padding-top: 6vw;
        }
        .loan-btn {
            font-size: 4vw;
            color: #fff;
            margin-left: 40vw;
            display: inline-block;
            padding: 0 2vw;
            border: 1px solid #fff;
            border-radius: 4vw;
            margin-top: 2vw;
        }
    }
}
</style>